import { XCircleIcon } from '@heroicons/react/solid'
import { useState } from 'react'

import { Balance } from '@/types'

import { CreditLimitStatus } from '@/buyers/_gen/gql'
import useBalanceModal from '@/gf/hooks/useBalanceModal'
import useToggle from '@/gf/hooks/useToggle'
import MoneyM from '@/gf/modules/Money'
import fetch from '@/gf/modules/fetch'

import BalanceTermsAndConditions from '@/gf/components/BalanceTermsAndConditions'
import Button from '@/gf/components/ButtonOld'
import RadioInput from '@/gf/components/inputs/Radio'

const PAYMENT_METHOD_NET_TERMS = 'net terms'
const PAYMENT_METHOD_CREDIT_BANK = 'credit card/bank debit'

type CreditLimit = {
  creditLimit: number
  maxCreditLimit: number
  status: string
}

const Checkout = ({
  balance,
  checkoutTokens,
  csrfToken,
  creditLimit,
}: {
  balance: Balance
  checkoutTokens: string[]
  csrfToken: string
  creditLimit: CreditLimit | null
}) => {
  const [spinnerLive, spinnerToggler] = useToggle()

  const onBalanceSuccess = async () => {
    spinnerToggler.on()
    return fetch('/checkout', { method: 'POST', headers: { 'x-csrf-token': csrfToken } }).then(
      (resp) => {
        window.location.href = resp.url
      }
    )
  }

  const balanceModal = useBalanceModal({ balance, checkoutTokens, onSuccess: onBalanceSuccess })

  const paymentMethodOptions = [
    ...(creditLimit && creditLimit.status === CreditLimitStatus.Approved
      ? [
          {
            id: PAYMENT_METHOD_NET_TERMS,
            display: 'Net 30 terms',
            details: `${MoneyM.format(
              MoneyM.fromDecimal(creditLimit.creditLimit, 'USD')
            )} available of ${MoneyM.format(
              MoneyM.fromDecimal(creditLimit.maxCreditLimit, 'USD')
            )} limit.`,
          },
        ]
      : []),
    {
      id: PAYMENT_METHOD_CREDIT_BANK,
      display: 'Credit card/Bank debit',
    },
  ]
  const [selectedPaymentMethodOption, setSelectedPaymentMethodOption] = useState(
    paymentMethodOptions[0]
  )
  const [error, setError] = useState<string>()

  return (
    <div className="flex flex-col items-start space-y-4">
      {creditLimit && (
        <fieldset>
          <legend className="mb-2 text-gray-900">Pay with</legend>
          <div className="flex flex-col items-start space-y-2.5">
            {paymentMethodOptions.map((option) => (
              <div key={option.id} className="flex flex-row items-start space-x-2">
                <div className="flex h-5 items-center">
                  <RadioInput
                    id={option.id}
                    name={option.id}
                    checked={option.id === selectedPaymentMethodOption.id}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedPaymentMethodOption(option)
                      }
                    }}
                  />
                </div>
                <div className="pt-0.5 flex flex-col space-y-1">
                  <label className="text-gray-900" htmlFor={option.id}>
                    {option.display}
                  </label>
                  {option.details && <p className="text-gray-500">{option.details}</p>}
                </div>
              </div>
            ))}
          </div>
        </fieldset>
      )}
      {error && (
        <div className="w-full rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <h3 className="font-medium text-red-800">Please contact support if applicable.</h3>
              <div className="mt-2 text-red-700">{error}</div>
            </div>
          </div>
        </div>
      )}
      {creditLimit && selectedPaymentMethodOption.id === PAYMENT_METHOD_NET_TERMS ? (
        <Button
          onClick={() => {
            spinnerToggler.on()
            setError(undefined)
            fetch('/checkout-terms', {
              method: 'POST',
              headers: { 'x-csrf-token': csrfToken },
            })
              .then(async (resp) => {
                if (resp && resp.status !== 200) {
                  const data = await resp.text()
                  setError(data)
                  return Promise.resolve()
                }
                return onBalanceSuccess()
              })
              .finally(() => {
                spinnerToggler.off()
              })
          }}
          performing={spinnerLive}
        >
          Place your order
        </Button>
      ) : (
        <Button
          title="Place your order"
          onClick={() => {
            setError(undefined)
            balanceModal.open()
          }}
          performing={spinnerLive}
        >
          Place your order
        </Button>
      )}
      {creditLimit && (
        <div className="mt-8 w-full flex justify-center">
          <BalanceTermsAndConditions className="max-w-lg" />
        </div>
      )}
    </div>
  )
}

export default Checkout
