import React from 'react'
import ReactDOM from 'react-dom/client'

import { Balance, BalanceActionDataset } from './types'

import Checkout from './store/Checkout'

declare const window: { balanceSDK: Balance }
const balance = window.balanceSDK

// Marketplace checkout
const checkoutActionElement = document.getElementById('balance-checkout-action')
if (checkoutActionElement) {
  const { transactions, creditLimit }: BalanceActionDataset = JSON.parse(
    checkoutActionElement.dataset.balance as string
  )
  const checkoutTokens = transactions.map((txn) => txn.token)
  const parsedCreditLimit = creditLimit
    ? {
        ...creditLimit,
        creditLimit: parseFloat(creditLimit.creditLimit),
        maxCreditLimit: parseFloat(creditLimit.maxCreditLimit),
      }
    : creditLimit

  const csrfToken = document.querySelector("meta[name='csrf-token']")?.getAttribute('content') || ''

  ReactDOM.createRoot(checkoutActionElement).render(
    <React.StrictMode>
      <Checkout
        balance={balance}
        checkoutTokens={checkoutTokens}
        csrfToken={csrfToken}
        creditLimit={parsedCreditLimit}
      />
    </React.StrictMode>
  )
}
